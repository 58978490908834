<template>
	<div>
		<s-crud
			title="Estado de Fruta"
			:config="config"
			add
			edit
			remove
			@save="save($event)"
			:filter="filter"
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col cols="12" lg="6" md="6" sm="12">
							<s-text
								v-model="props.item.FtsName"
								label="Descripción"
							></s-text>
						</v-col>
						<v-col cols="12" lg="6" md="6" sm="12">
							<s-select-definition
								v-model="props.item.FtsStatus"
								:def="1333"
								label="Estado"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:FtsStatus="{row}">
				<v-chip 
					x-small
					:color="row.FtsStatusColor"
				>
					{{row.FtsStatusName}}
				</v-chip>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sFruitStateService from "@/services/FrozenProduction/FruitStateService.js";
	export default {
		props: {
			TypeCultive: {
				type: Number,
				default: 0
			},
		},

		watch: {
			TypeCultive() {
				this.Initialize();
			}
		},
		
		data() {
			return {
				filter: {TypeCultive: 0},
				config: {
					service: _sFruitStateService,
					model: {
						FtsID: "ID",
						FtsStatus: "number"
					},
					headers: [
						{ text: "ID", value: "FtsID" },
						{ text: "Descripción", value: "FtsName" },
						{ text: "Estado", value: "FtsStatus"},
					],
				},
			}
		},

		methods: {
			Initialize()
			{
				this.filter = { TypeCultive: this.TypeCultive }
			},

			save(item){
				if(item.SplName == ""){
					this.$fun.alert("Ingrese descripción");
					return;
				}
				item.TypeCultive = this.TypeCultive;
				item.save();
			}
		},

		created () {
			
		},
	}

</script>
